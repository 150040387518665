import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import Table from "../../../components/Table";
import { useCheckItemsForm } from "../../../utils/hooks";
import FormCheckController from "../../../components/form/FormCheckController";
import { STORIES_REPORT_KEYS } from "../utils";
import WithTooltip from "../../../components/Tooltip/WithTooltip";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { mixpanelTrack, TrackingConstants } from "../../../libs/mixpanel";
import { NewsResponse } from "../../../api";
import { getStoriesReportsListExport } from "../../../api/ReportListExport";
import DataCell from "../../../components/Table/DataCell";
import { PUBLISHED_KEY, STATE_KEY } from "../../../components/Table/DataCell/DataCell";
import TitleCell from "../../../components/Table/TitleCell";

interface IStoriesListTable {
  data: NewsResponse[];
}

export default function StoriesListTable({ data }: IStoriesListTable) {
  const { t } = useTranslation();
  const [exportContentType, setContentType] = React.useState(null);
  const { selectedPeriod } = React.useContext(PagePeriodFilterContext);
  const checkItemsIds = React.useMemo(() => (data ? data.map((item) => item.id.toString()) : []), [data]);
  const { handleSubmitClick, control, handleCheckAllChange, disableSubmit, handleItemChange } = useCheckItemsForm(
    (selectedItemsIds: string[]) =>
      getStoriesReportsListExport(
        exportContentType!,
        _isEmpty(selectedItemsIds) ? checkItemsIds : selectedItemsIds,
        selectedPeriod?.from!,
        selectedPeriod?.until!
      ),
    checkItemsIds
  );

  React.useEffect(() => {
    if (exportContentType !== null) {
      mixpanelTrack(TrackingConstants.ExportStoriesList, { "Export content type": exportContentType });
      handleSubmitClick();
      setContentType(null);
    }
  }, [exportContentType, handleSubmitClick]);

  const columns = React.useMemo(
    () => [
      {
        Header: <FormCheckController name="master" control={control} customOnChange={handleCheckAllChange} />,
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const record = row.original;
            return <FormCheckController name={`${record.id}`} control={control} customOnChange={handleItemChange} />;
          }, [row]),
        id: "export",
      },
      {
        Header: t("storiesList.table.storyName"),
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const { title, id } = row.original;
            return <TitleCell title={title} link={`/stories/${id}`} />;
          }, [row]),
        accessor: "title",
      },
      ...[PUBLISHED_KEY, STATE_KEY, ...STORIES_REPORT_KEYS].map((key) => ({
        Header: (
          <WithTooltip id={`storiesList.table.${key}`} tooltip={t(`storiesList.table.${key}Tooltip`)} place="top">
            <span>{t(`storiesList.table.${key}`)}</span>
          </WithTooltip>
        ),
        accessor: key,
        Cell: ({ row }: any): any => React.useMemo(() => <DataCell record={row.original} prop={key} />, [row]),
      })),
      {
        Header: (
          <WithTooltip id="storiesList.table.country" tooltip={t(`storiesList.table.countryTooltip`)} place="top">
            <span>{t(`storiesList.table.country`)}</span>
          </WithTooltip>
        ),
        accessor: "country",
      },
    ],
    [control, handleCheckAllChange, handleItemChange, t]
  );

  const tableTitle = React.useMemo(() => t("storiesList.table.title"), [t]);

  if (!data) {
    return null;
  }
  return (
    <Table
      columns={columns}
      data={data}
      tableTitle={tableTitle}
      withGlobalFilter
      withExportButton
      disableExport={disableSubmit}
      setContentType={setContentType}
    />
  );
}
