import React from "react";
import _isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import Table from "../../../components/Table";
import { useCheckItemsForm } from "../../../utils/hooks";
import FormCheckController from "../../../components/form/FormCheckController";
import { CME_REPORT_KEYS, CME_SEARCH_ONLY_KEYS } from "../Cme/utils";
import WithTooltip from "../../../components/Tooltip/WithTooltip";
import { PagePeriodFilterContext } from "../../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import { mixpanelTrack, TrackingConstants } from "../../../libs/mixpanel";
import { CmeResponse } from "../../../api";
import { getCmesReportsListExport } from "../../../api/ReportListExport";
import DataCell from "../../../components/Table/DataCell";
import { PUBLISHED_KEY, STATE_KEY } from "../../../components/Table/DataCell/DataCell";
import TitleCell from "../../../components/Table/TitleCell";

interface ICmesListTable {
  data: CmeResponse[];
}

export default function CmesListTable({ data }: ICmesListTable) {
  const { t } = useTranslation();
  const [exportContentType, setContentType] = React.useState(null);
  const { selectedPeriod } = React.useContext(PagePeriodFilterContext);
  const checkItemsIds = React.useMemo(() => (data ? data.map((item) => item.id.toString()) : []), [data]);
  const { handleSubmitClick, control, handleCheckAllChange, disableSubmit, handleItemChange } = useCheckItemsForm(
    (selectedItemsIds: string[]) =>
      getCmesReportsListExport(
        exportContentType!,
        _isEmpty(selectedItemsIds) ? checkItemsIds : selectedItemsIds,
        selectedPeriod?.from!,
        selectedPeriod?.until!
      ),
    checkItemsIds
  );

  React.useEffect(() => {
    if (exportContentType !== null) {
      mixpanelTrack(TrackingConstants.ExportCmesList, { "Export content type": exportContentType });
      handleSubmitClick();
      setContentType(null);
    }
  }, [exportContentType, handleSubmitClick]);

  const columns = React.useMemo(
    () => [
      {
        Header: <FormCheckController name="master" control={control} customOnChange={handleCheckAllChange} />,
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const record = row.original;
            return <FormCheckController name={`${record.id}`} control={control} customOnChange={handleItemChange} />;
          }, [row]),
        id: "export",
      },
      {
        Header: t("cmesList.table.cmeName"),
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const { title, name } = row.original;
            return <TitleCell title={title} link={`/cmes/${name}`} />;
          }, [row]),
        accessor: "title",
      },
      ...[PUBLISHED_KEY, STATE_KEY, ...CME_REPORT_KEYS].map((key) => ({
        Header: (
          <WithTooltip id={`cmesList.table.${key}`} tooltip={t(`cmesList.table.${key}Tooltip`)} place="top">
            <span>{t(`cmesList.table.${key}`)}</span>
          </WithTooltip>
        ),
        accessor: key,
        Cell: ({ row }: any): any => React.useMemo(() => <DataCell record={row.original} prop={key} />, [row]),
      })),
      {
        Header: (
          <WithTooltip id="cmesList.table.country" tooltip={t(`cmesList.table.countryTooltip`)} place="top">
            <span>{t(`cmesList.table.country`)}</span>
          </WithTooltip>
        ),
        accessor: "country",
      },
      ...CME_SEARCH_ONLY_KEYS.map((key) => ({ accessor: key })),
    ],
    [control, handleCheckAllChange, handleItemChange, t]
  );

  const tableTitle = React.useMemo(() => t("cmesList.table.title"), [t]);

  if (!data) {
    return null;
  }
  return (
    <Table
      columns={columns}
      data={data}
      tableTitle={tableTitle}
      withGlobalFilter
      withExportButton
      disableExport={disableSubmit}
      setContentType={setContentType}
      initialState={{ hiddenColumns: CME_SEARCH_ONLY_KEYS }}
    />
  );
}
