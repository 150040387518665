import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { formatDateRange } from "../../utils/numbers";
import UIKitCard from "../UiKit/Card";
import { Heading1 } from "../UiKit/Typography";

const Card = styled(UIKitCard)`
  padding: 28px 32px 36px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`;

const Title = styled(Heading1)`
  margin-bottom: 4px;
`;

const Duration = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 24px;
`;

const StyledImage = styled.img`
  max-width: 580px;
  border-radius: 12px;
`;

export interface ReportHeaderI {
  title?: string;
  from?: string;
  until?: string;
  image?: string;
  errorFetching?: boolean;
}

export default function ReportHeader({ title, from, image, until, errorFetching }: ReportHeaderI) {
  const { t } = useTranslation();
  const formattedDayRange = React.useMemo(() => from && until && formatDateRange(from, until), [from, until]);

  if (errorFetching) {
    return null;
  }
  return (
    <Card>
      {title && <Title>{title}</Title>}
      {formattedDayRange && <Duration>{`${t("common.reportPages.published")} ${formattedDayRange}`}</Duration>}
      {image && <StyledImage src={image} alt="report" />}
    </Card>
  );
}
