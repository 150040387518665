import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import PagePeriodFilter from "../../components/PagePeriodFilter/PagePeriodFilter";
import { PagePeriodFilterContext } from "../../components/PagePeriodFilter/PagePeriodFilterContextProvider";
import MdsPerSpecializationTable from "./MdsPerSpecializationTable";
import { UserContext } from "../../contexts/UserContext";
import { mixpanelTrack, TrackingConstants } from "../../libs/mixpanel";
import { MDsCardTotalsSchema, MDsPerSpecializationSchema, SpecializationsApi } from "../../api";
import { ApiQueryKeys } from "../../api/ApiQueryKeys";
import { MainContentWrapper } from "../../components/UiKit/Helpers/StyledCss";
import { ReactComponent as Star } from "../../assets/icons/star.svg";
import { SpecializationSettingsModalContext } from "../../components/Modals/SpecializationSettingsModal/SpecializationSettingsModalContext";
import * as Styled from "./MdsPerSpecialization.styled";
import { DISABLED_PERIOD_OPTIONS, useValidatePeriod } from "./utils";
import HeaderWithBreadcrumbs from "../../components/Layout/HeaderWithBreadcrumbs";
import { PageFilterContainer } from "../../components/ReportPageComponents/StyledComponents";
import Container from "../../components/UiKit/Container";
import { CountryContext } from "../../components/CountriesMap/CountryContext";
import TrafficData from "../../components/ListViewPartials/TrafficData";
import { Select } from "../../components/UiKit/Select";
import useCountryOptions from "../../utils/hooks/useCountryOptions";

type CountryForm = {
  country: string;
};

export default function MdsPerSpecialization() {
  const { openModal: openSpecSettingsModal } = React.useContext(SpecializationSettingsModalContext);
  const { selectedCountry, setSelectedCountry } = React.useContext(CountryContext);
  const { t } = useTranslation();
  const { user } = React.useContext(UserContext);
  const {
    mdsLimitedSelectedPeriod: selectedPeriod,
    periodOption,
    handleSelectChange,
  } = React.useContext(PagePeriodFilterContext);

  const wasPeriodValidated = useValidatePeriod(periodOption, handleSelectChange);
  const countryOptions = useCountryOptions();

  const { control, watch, setValue } = useForm<CountryForm>({
    defaultValues: {
      country: selectedCountry,
    },
  });

  const dropdownSelectedCountry = watch("country");

  React.useEffect(() => {
    if (dropdownSelectedCountry !== selectedCountry) {
      setValue("country", selectedCountry);
    }
  }, [dropdownSelectedCountry, selectedCountry, setValue]);

  React.useEffect(() => {}, [dropdownSelectedCountry]);

  const previousValues = React.useRef({ selectedCountry });

  const { data, isFetching } = useQuery<MDsPerSpecializationSchema>(
    [ApiQueryKeys.specializationsMdsPerSpecialization, selectedPeriod, selectedCountry, user?.specialization_interests],
    () =>
      SpecializationsApi.specializationsMdsPerSpecialization({
        dateFrom: selectedPeriod.from,
        dateUntil: selectedPeriod.until,
        country: selectedCountry,
      }),
    { enabled: wasPeriodValidated && !!selectedPeriod && !!selectedCountry && !!user }
  );

  const { data: mdCardsData, isFetching: isFetchingMdCardsData } = useQuery<MDsCardTotalsSchema>(
    [ApiQueryKeys.specializationsMdsCardsTotals],
    SpecializationsApi.specializationsMdsCardsTotals
  );

  React.useEffect(() => {
    if (previousValues.current.selectedCountry !== selectedCountry) {
      mixpanelTrack(TrackingConstants.LanguageFilterChange, { Language: selectedCountry });
    }
  }, [selectedCountry]);

  const customOnChange = React.useCallback(
    (value: string) => {
      if (value !== selectedCountry) {
        setSelectedCountry(value);
      }
    },
    [setSelectedCountry, selectedCountry]
  );

  return (
    <MainContentWrapper>
      <HeaderWithBreadcrumbs title={t("mdsPerSpecialization.title")} subtitle={t("mdsPerSpecialization.subTitle")} />

      <PageFilterContainer>
        <PagePeriodFilter disabledOptions={DISABLED_PERIOD_OPTIONS} allTimeLimited />
        <Select options={countryOptions} name="country" customOnChange={customOnChange} control={control} $forCountry />
        <Styled.SettingsButton onClick={openSpecSettingsModal}>
          <Star />
          {t("mdsPerSpecialization.settingsButton")}
        </Styled.SettingsButton>
      </PageFilterContainer>

      <Container>
        <TrafficData
          reportType="mdsPerSpec"
          selectedPeriodReports={mdCardsData ? [mdCardsData] : undefined}
          trafficDataEntryProps={["registered_mds_all_time", "active_mds_30d", "total_mds_sessions_30d"]}
          isLoading={isFetchingMdCardsData}
        />
        <MdsPerSpecializationTable data={data} isFetching={isFetching} selectedCountry={selectedCountry} />
      </Container>
    </MainContentWrapper>
  );
}
