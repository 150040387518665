import React from "react";
import styled from "styled-components";
import WithTooltip from "../../Tooltip/WithTooltip";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info-icon.svg";

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  svg {
    color: ${(props) => props.theme.text3};
  }
`;

export default function CellWithInfo({ value, tooltip }: { value: string; tooltip: string }) {
  return (
    <WithTooltip id={tooltip} tooltip={tooltip} withoutUnderline place="top">
      <Wrapper>
        <span>{value}</span>
        <InfoIcon />
      </Wrapper>
    </WithTooltip>
  );
}
