import React from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../components/Table";
import { CME_REPORT_KEYS } from "./utils";
import { formatNumber } from "../../../utils/numbers";
import WithTooltip from "../../../components/Tooltip/WithTooltip";
import { CMEReportResponseSchema } from "../../../api";

interface CmeSpecializationsTableI {
  data: CMEReportResponseSchema;
}

export default function CmeSpecializationsTable({ data }: CmeSpecializationsTableI) {
  const { t } = useTranslation();

  const specializationsData = React.useMemo(() => {
    if (data != null) {
      return Object.keys(data.date_range_by_specialization).map((specialization) => ({
        translation: data.specializations[specialization],
        ...data.date_range_by_specialization[specialization],
      }));
    }
    return null;
  }, [data]);

  const tableInitialState = React.useMemo(
    () => ({
      sortBy: [
        {
          id: "started",
          desc: true,
        },
      ],
    }),
    []
  );

  const columns = React.useMemo(
    () => [
      ...["translation", ...CME_REPORT_KEYS].map((key) => ({
        Header: (
          <WithTooltip
            id={`cmes.table.specializations.${key}`}
            tooltip={t(`cmes.table.specializations.${key}Tooltip`)}
            withoutUnderline={key === "translation"}
            disableTooltip={key === "translation"}
            place="top"
          >
            <span>{t(`cmes.table.specializations.${key}`)}</span>
          </WithTooltip>
        ),
        accessor: key,
        Cell: ({ row }: any): any =>
          React.useMemo(() => {
            const record = row.original;
            if (key === "translation") {
              return record[key];
            }
            return formatNumber(record[key]);
          }, [row]),
        Footer: (info: any) => {
          if (key === "translation") {
            return t(`cmes.table.footer`);
          }
          const { rows } = info;

          return rows.reduce((sum: number | string, row: any) => {
            if (typeof row.values[key] === "string") {
              return sum;
            }
            if (sum === "/") {
              return row.values[key];
            }

            return row.values[key] + sum;
          }, "/");
        },
      })),
    ],
    [t]
  );

  if (!specializationsData || specializationsData.length === 0) {
    return null;
  }

  return (
    <Table
      columns={columns}
      data={specializationsData}
      withFooter
      horizontallyScrollableCard={false}
      initialState={tableInitialState}
    />
  );
}
