import React from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Table from "../Table";
import { formatNumber } from "../../utils/numbers";
import WithTooltip from "../Tooltip/WithTooltip";
import { DataTypeContext, MONTHLY } from "./DataTypeSelectionContext";
import CellWithInfo from "../Table/CellWithInfo";
import useReportType from "../../utils/hooks/useReportType";

interface IReportMonthTable {
  data: {
    [key: string]: {
      [key: string]: number;
    };
  };
  total: {
    [key: string]: number;
  };
  keys: Array<string>;
  showOpens?: boolean;
  totalTooltip?: boolean;
}

export default function ReportPeriodTable({ data, total, keys, showOpens, totalTooltip }: IReportMonthTable) {
  const { t } = useTranslation();
  const { type: dataType } = React.useContext(DataTypeContext);
  const reportType = useReportType();

  const periodTableData = React.useMemo(() => {
    if (data) {
      const sortedKeys = Object.keys(data).sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1));
      return sortedKeys.map((date) => ({
        date: dayjs(date).format(dataType === MONTHLY ? "MMM YYYY" : "DD.MM.YYYY"),
        ...data[date],
      }));
    }
    return null;
  }, [data, dataType]);

  const columns = React.useMemo(
    () => [
      {
        Header: t(`${reportType}.table.periodTableData.date`),
        accessor: "date",
        Footer: (): any =>
          React.useMemo(
            () =>
              totalTooltip ? (
                <CellWithInfo
                  value={t(`${reportType}.table.footer`)}
                  tooltip={t(`common.table.totalTooltip.${showOpens ? "withOpens" : "withoutOpens"}`)}
                />
              ) : (
                <span>{t(`${reportType}.table.footer`)}</span>
              ),
            []
          ),
      },
      ...keys
        .filter((key) => total[key] !== undefined)
        .map((key) => ({
          Header: (
            <WithTooltip
              id={`monthTable-${key}`}
              tooltip={t(`${reportType}.table.periodTableData.${key}Tooltip`)}
              place="top"
            >
              <span>{t(`${reportType}.table.periodTableData.${key}`)}</span>
            </WithTooltip>
          ),
          accessor: key,
          Cell: ({ row }: any): any =>
            React.useMemo(() => {
              const record = row.original;
              return formatNumber(record[key]);
            }, [row]),
          Footer: (): any => React.useMemo(() => <span>{formatNumber(total[key])}</span>, []),
        })),
    ],
    [keys, reportType, t, total, showOpens, totalTooltip]
  );

  if (!periodTableData) {
    return null;
  }
  return <Table columns={columns} data={periodTableData} withFooter horizontallyScrollableCard={false} />;
}
